<template>
  <div>
    <div class="homeLeftMenu">
      <div
        class="homeLeftMenuItem"
        v-for="(item,index) in leftMenu"
        :key="index"
        @click="$goto(item.path)"
      >
        <img
          style="width:30px"
          :src="item.img"
          alt=""
        >
        <p style="width:40px;text-align:center;margin-top:10px">{{item.title}}</p>
      </div>
    </div>
    <TitleMore
      :title="['申请救助信息']"
      :isMore="false"
      style="margin:20px auto"
    ></TitleMore>
    <div class="baseCard applyForm">
      <div
        class="itemText"
        v-for="(item,index) in list"
        :key="index"
		style="margin-bottom: 10px;"
      >
        <a
          class="itemP"
          :href="item.file"
          target="_blank"
        >
          <img
            src="../../assets/img/list-little.png"
            alt=""
          >
          <span class="itemTextContent">{{item.title}}</span>
          <!-- <span class="downLoadText">
            <img
              src="../../assets/img/download.png"
              alt=""
            >
            {{item.file_size}}
          </span> -->
        </a>
        <p style="color: #ababab;">{{item.publishDate}}</p>
      </div>
      <el-pagination
        :current-page="page"
        :page-size="size"
        :total="total"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <TitleMore
      :title="['加入我们']"
      :isMore="false"
    ></TitleMore>
    <div class="baseCard focusCard">
      <p style="margin-bottom:20px">{{joinUs.title}}</p>
      <div>
        <img
          :src="joinUs.qrcode1"
          alt=""
        >
        <img
          :src="joinUs.qrcode2"
          alt=""
        >
      </div>
    </div>
    <TitleMore
      :title="['联系我们']"
      :isMore="false"
    ></TitleMore>
    <div class="baseCard">
      <p class="callUsItem">
        <img
          style="margin-right:10px"
          src="../../assets/img/address.png"
          alt=""
        >
        <span>地址：{{concat.add}}</span>
      </p>
      <p class="callUsItem">
        <img
          style="margin-right:10px"
          src="../../assets/img/phone.png"
          alt=""
        >
        <span>电话：{{concat.phone}}</span>
      </p>
      <!-- <div class="callUsItem">
        <img
          style="margin-right:10px"
          src="../../assets/img/email.png"
          alt=""
        >
        <p>传真：{{concat.email}}</p>
      </div> -->
    </div>
  </div>
</template>

<script>
import TitleMore from "@components/TitleMore";
import apiUrls from "@api";
export default {
  data() {
    return {
      list: [],
      page: 1,
      size: 10,
      total: 0,
      joinUs: {},
      concat: {},
      leftMenu: [
        {
          img: require("../../assets/img/help.png"),
          title: "我要求助",
          path: "/help",
        },
        {
          img: require("../../assets/img/idonate.png"),
          title: "我要捐赠",
          path: "/idonate",
        },
        {
          img: require("../../assets/img/complaint.png"),
          title: "我要参与",
          path: "/complain",
        },
        {
          img: require("../../assets/img/bossemail.png"),
          title: "会长信箱",
          path: "/email",
        },
      ],
    };
  },

  components: {
    TitleMore,
  },

  mounted() {
    this.gethelpdate();
    this.gethelpUs("jrwm");
    this.gethelpUs("lxwm");
  },

  methods: {
    handleCurrentChange(val) {
      this.page = val;
      this.gethelpdate();
    },
    gethelpdate() {
      apiUrls.selfDynamicList({ node: "jzzn",page: this.page-1, size: this.size }).then((res) => {
        this.list = res.results.data;
        this.total = res.results.pager.totalElements;
      });
    },
    gethelpUs(type) {
      apiUrls.trainNum({ node: type }).then((res) => {
        type == "jrwm"
          ? (this.joinUs = res.results.data)
          : (this.concat = res.results.data);
      });
    },
  },
};
</script>
<style lang="less" scoped>
.applyForm {
  // display: flex;
  // flex-direction: column;
  .downLoadText {
    width:  80px;
    display: flex;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    color: #999999;
    margin-left: 20px;
    img {
     width: 18px;
     height: 16px;
     margin-right: 10px;
    }
  }
}
.focusCard {
  width: 1080px;
  padding: 60px;
}
.callUsItem {
  display: flex;
  flex-direction: row;
  font-size: 18px;
  align-items: center;
  margin-top: 20px;
  padding-left:40px;
}
.itemP {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
}
.itemTextContent {
  color: #1f6ca2;
  font-size: 16px;
}

  .homeLeftMenu {
    position: fixed;
    top: 40%;
    left: 0px;
    border-radius: 0px 24px 24px 0px;
    background: #ffffff;
    box-shadow: 0px 6px 18px 0px rgba(14, 14, 14, 0.27);
    padding: 10px;
    z-index: 100;
    .homeLeftMenuItem {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 30px 0px;
      cursor: pointer;
    }
    .homeLeftMenuItem:first-child {
      margin-top: 10px;
    }
    .homeLeftMenuItem:last-child {
      margin-bottom: 10px;
    }
  }
</style>